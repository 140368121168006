<template>
  <div id="alert">
    <div class="contents">
      <h6>{{ $store.state.add.type == 0 ? "一键铺货" : "产品上架" }}</h6>
      <i class="iconfont close" @click="alert">&#xe713;</i>
      <div class="price" v-if="$store.state.add.type == 0">
        <el-form class="form">
          <el-form-item label="产品类目">
            <el-cascader
              v-model="chooseVal"
              :options="options"
              :props="{
                expandTrigger: 'hover',
                value: 'label',
                label: 'label'
              }"
              @change="handleChange"
            ></el-cascader>

            <div class="search">
              <span class="recont">搜索类目:</span>
              <el-autocomplete
                v-model="state2"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                :trigger-on-focus="false"
                @select="handleSelect"
              ></el-autocomplete>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="price" v-if="$store.state.add.type == 0">
        <span>商品价格:</span>
        <el-select v-model="form.jiage" size="small" placeholder="请选择价格">
          <el-option
            v-for="(item, index) in price"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="price" v-if="$store.state.add.type == 1">
        <span>商品价格:</span>
        <el-input placeholder="请输入百分比"  size="medium" v-model="form.jiajia" class="inputbox">
          <template slot="append">%</template>
        </el-input>
      </div>
      <div class="price" v-if="$store.state.add.type == 1">
         <span>发货方式:</span>
         <el-select v-model="form.isbaid" size="small" placeholder="请选择发货方式" class="inputbox">
          <el-option
            v-for="(item, index) in fahuo"
            :key="index"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
       <div class="price" v-if="$store.state.add.type == 1">
         <span>库存设置:</span>
         <el-input placeholder="请输入百分比"  size="medium" v-model="form.kucun" class="inputbox">
         
        </el-input>
      </div>

      

      <div class="price" v-if="$store.state.add.type == 0">
        <span>运费设置:</span>
        <p class="text">默认包邮</p>
        <p class="tips">(可搬运成功后修改)</p>
      </div>
      <div class="btn">
        <el-button @click="add" type="primary" class="btns">确 定</el-button>
        <el-button @click="alert">取 消</el-button>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.form {
  width: 100%;

  span {
    margin-right: 0 !important;
  }

  .search {
    display: inline-block;
    margin-left: 10px;
    .recont {
      margin-right: 10px !important;
    }
  }
}
.inputbox{
  width: 38%;
}

@media screen and (max-width: 750px){

  #alert .contents {
      width: 70% !important;
      padding: 30px 20px!important;
      .inputbox{
        width: 80%;
      }

  }
  #alert .contents .price, #Detail .contents .price{
    padding: 0px!important;
    flex: 1;
  }

}

</style>
<script>
import json from "./../assets/json/choose.json";
export default {
  name: "Add",
  data() {
    return {
      chooseVal: [],
      form: {
        options: "",
        recentval: "",
        one_cate: "",
        two_cate: "",
        three_cate: "",
        jiage: "1.1",
        jiajia: "",
        isbaid:0,
        kucun:'100',
      },
      state2: "",
      options: "",
      price: [
        {
          value: "1",
          name: "不加价"
        },
        {
          value: "1.05",
          name: "+5%"
        },
        {
          value: "1.1",
          name: "+10%"
        },
        {
          value: "1.2",
          name: "+20%"
        },
        {
          value: "1.3",
          name: "+30%"
        },
        {
          value: "1.4",
          name: "+40%"
        },
        {
          value: "1.5",
          name: "+50%"
        },
        {
          value: "0.9",
          name: "-10%"
        },
        {
          value: "0.8",
          name: "-20%"
        },
        {
          value: "0.7",
          name: "-30%"
        },
        {
          value: "0.5",
          name: "-50%"
        }
      ],
      price2: [
        {
          value: "0.9",
          name: "-10%"
        },
        {
          value: "0.8",
          name: "-20%"
        },
        {
          value: "0.7",
          name: "-30%"
        },
        {
          value: "0.6",
          name: "-40%"
        },
        {
          value: "0.5",
          name: "-50%"
        }
      ],
     
      fahuo:[
        {
           value:0,
           name:'快递'
        },
         {
           value:1,
           name:'快递+到店取货'
        },
         {
           value:2,
           name:'到店取货'
        },
      ]
    };
  },
  methods: {
    alert() {
      this.$store.commit("changeAdd",'');
    },
    handleChange(val) {
      let leimuNum = this.options.find(item => {
        return item.label == val[0];
      });

      this.form.one_cate = leimuNum.value;

      if (leimuNum.children) {
        leimuNum.children.forEach(item => {
          if (item.label == this.chooseVal[1]) {
            this.form.two_cate = item.value;
            if (item.children) {
              item.children.forEach(i => {
                if (i.label == this.chooseVal[2]) {
                  this.form.three_cate = i.value;
                }
              });
            } else {
              this.form.three_cate = "";
            }
          }
        });
      }

      this.form.recentval = [];
    },

    querySearch(queryString, cb) {
      if (queryString) {
        this.axios
          .get(this.api.search, {
            params: {
              keyword: queryString
            }
          })
          .then(res => {
            res.forEach(items => {
              items.value =
                items.one_name + "|" + items.two_name + "|" + items.three_name;
            });

            this.stateList = res;

            cb(res);
          });
      }
    },
    handleSelect(e) {
      this.form.one_cate = e.one;
      this.form.two_cate = e.two;
      this.form.three_cate = e.three;
      this.chooseVal = [e.one_name, e.two_name, e.three_name];
    },

    add() {
      if (this.$store.state.add.type == 1) {
        this.add2();
      } else {
        if (this.form.one_cate != "" && this.form.two_cate != "") {
          if (this.form.three_cate == "") {
            this.axios
              .get(this.api.getType, {
                params: {
                  f_cat_id: this.form.two_cate
                }
              })
              .then(res => {
                if (res != "") {
                  this.form.three_cate = res[0].cat_id;
                } else {
                  this.$message({
                    message: "请先申请类目权限",
                    type: "warning"
                  });
                }
              })
              .then(()=>{
                 this.push();
              })
          }else{
            this.push()
          }
        }
      }
    },
    push(){
       this.axios
          .get(this.api.addproduct, {
            params: {
              id: this.$store.state.add.id,
              one_cate: this.form.one_cate,
              two_cate: this.form.two_cate,
              three_cate: this.form.three_cate,
              jiajia: this.form.jiage,

            }
          })
          .then(res => {
            if (res.zhuangtai_id == 1) {
              this.$message({
                message: res.zhuangtai,
                type: "success"
              });
                this.$store.commit("changeAdd");
            } else {
              this.$message({
                message: res.msg,
                type: "warning"
              });
            }
          });
    },
    add2() {
      this.axios
        .get(this.api.addsc, {
          params: {
            product_id: this.$store.state.add.id,
            jiajia: this.form.jiajia,
            isbaid:this.form.isbaid,
            kucun:this.form.kucun,
          }
        })
        .then(res => {
         
          this.$message({
            message: res.msg,
            type: "success"
          });

            this.$store.commit("changeAdd");
        });
    }
  },
  created() {
    this.options = json;
  },
  mounted() {
     document.getElementsByClassName("el-cascader-panel")[0].style.height ="200px";
  }
};
</script>