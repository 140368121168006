<template>
    <div>
        Load.vue
    </div>
</template>
<script>
export default {
    name:'Load',
    data(){
        return{

        }
    },
    
}
</script>