<template>
  <div id="alert">
    <div class="contents">
      <h6>{{ $store.state.alert.type ? "搬家设置" : "产品修改" }}</h6>
      <i class="iconfont close" @click="alert">&#xe713;</i>
      <template v-if="$store.state.alert.type">
        <div class="price">
          <span>涨价金额:</span>
          <el-input
            size="small"
            style="width:auto"
            v-model="jiaqian"
            placeholder="请输入金额(元)"
          ></el-input>
        </div>
        <div class="price">
          <span>加价比例:</span>
          <el-select v-model="jiage" size="small" placeholder="请选择价格">
            <el-option
              v-for="(item, index) in price"
              :key="index"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div></div>
        <div class="price">
          <span>运费设置:</span>
          <p class="text">默认包邮</p>
          <p class="tips">(可搬运成功后修改)</p>
        </div>
      </template>

      <template v-else>
          <div class="price">
          <span>产品标题:</span>
          <el-input
            size="small"
            style="width:auto"
            v-model="title"
            placeholder="请输入产品标题"
          ></el-input>
        </div>

        <div class="price">
          <span>加价幅度:</span>
          <el-input
            size="small"
            style="width:auto"
            v-model="jiaqian"
            placeholder="请输入加价幅度"
          ></el-input>
        </div>
      </template>

      <div class="btn">
        <el-button @click="add" type="primary" class="btns">确 定</el-button>
        <el-button @click="alert">取 消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Alert",
  data() {
    return {
      jiage: "1.1",
      jiaqian: "",
      title:"",
      price: [
        {
          value: "1",
          name: "不加价"
        },
        {
          value: "1.05",
          name: "+5%"
        },
        {
          value: "1.1",
          name: "+10%"
        },
        {
          value: "1.2",
          name: "+20%"
        },
        {
          value: "1.3",
          name: "+30%"
        },
        {
          value: "1.4",
          name: "+40%"
        },
        {
          value: "1.5",
          name: "+50%"
        },
        {
          value: "0.9",
          name: "-10%"
        },
        {
          value: "0.8",
          name: "-20%"
        },
        {
          value: "0.7",
          name: "-30%"
        },
        {
          value: "0.5",
          name: "-50%"
        }
      ]
    };
  },
  methods: {
    alert() {
      this.$store.commit("alertChange",false);
    },
    add() {
      if(this.$store.state.alert.type){
        this.push()
      }else{
        this.change()
      }
    },

    change(){
      this.axios(this.api.changetitle,{
        params:{
            product_id: this.$store.state.productId,
            title:this.title,
            jiaqian: this.jiaqian
        }
      })
      .then((res)=>{
        if(res.code==1){
           this.$notify({
              title: "成功",
              message: res.msg,
              type: "success"
            });
            
        }else{
           this.$notify({
              title: "警告",
              message: res.msg,
              type: "warning"
            });
        }
        this.alert();
      })
    },

    push(){
        this.axios
        .get(this.api.addProduct, {
          params: {
            product_id: this.$store.state.productId,
            jiajia: this.jiage,
            jiaqian: this.jiaqian
          }
        })
        .then(res => {
          if (res.code >= 0) {
            this.$notify({
              title: "成功",
              message: res.msg,
              type: "success"
            });
          } else {
            this.$notify({
              title: "警告",
              message: res.msg,
              type: "warning"
            });
          }
          this.alert();
        });
    }
  }
};
</script>