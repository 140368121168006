<template>
  <div id="home">
    <div class="ad">
      <i class="iconfont">&#xe633;</i>
      <p class="con">
        <span v-if="$store.state.userinfo">{{
          $store.state.userinfo.gonggao
            ? $store.state.userinfo.gonggao
            : "暂无公告"
        }}</span>
      </p>
    </div>

    <el-row :gutter="20">
      <el-col :span="$store.state.isMobile ? 24 : 16">
        <div class="content" v-if="$store.state.userinfo">
          <h4>用户信息 <i class="iconfont setting" @click="openSetting">&#xe640;</i> </h4>
          <div class="user">
            <img :src="$store.state.userinfo.pic" alt="" />
            <span class="name">{{ $store.state.userinfo.title }}</span>
            <el-button
              @click="openBindWx"
              v-if="!$store.state.userinfo.aopenids"
              size="mini"
              type="primary"
              >绑定微信</el-button
            >
            <span class="money"
              >货款余额： {{ $store.state.userinfo.jiesuan }}元</span
            >
            <el-button
              style="margin-left:10px"
              @click="buyvip"
              size="mini"
              type="danger"
            >
              立即续费</el-button
            >
          </div>
          <div class="status">
            当前等级：
            <el-tag
              size="mini"
              :type="
                $store.state.userinfo.banben == '试用' ? 'danger' : 'success'
              "
              >{{ $store.state.userinfo.banben }}</el-tag
            >

            <p>
              <span class="time"
                >到期时间：{{ $store.state.userinfo.expire_time }}</span
              >

              <el-button @click="openDrawer" size="mini" type="primary">
                消费记录</el-button
              >
            </p>
          </div>
        </div>
      </el-col>

      <el-col :span="$store.state.isMobile ? 24 : 8">
        <div class="content vip" v-if="$store.state.userinfo">
          <h4 style="margin-bottom:4px">会员权限</h4>
          <!-- warning -->
          <p>
            批量采集:<el-tag
              size="mini"
              :type="
                $store.state.userinfo.taocan.opencaiji == 0
                  ? 'success'
                  : 'warning'
              "
              >{{
                $store.state.userinfo.taocan.opencaiji == 0
                  ? "已开通"
                  : "未开通"
              }}</el-tag
            >
          </p>
          <p>
            云仓功能:<el-tag
              size="mini"
              :type="
                $store.state.userinfo.taocan.openyuncang == 0
                  ? 'success'
                  : 'warning'
              "
              >{{
                $store.state.userinfo.taocan.openyuncang == 0
                  ? "已开通"
                  : "未开通"
              }}</el-tag
            >
          </p>
          <p>
            本地仓功能:<el-tag
              size="mini"
              :type="
                $store.state.userinfo.taocan.openbdcang == 0
                  ? 'success'
                  : 'warning'
              "
              >{{
                $store.state.userinfo.taocan.openbdcang == 0
                  ? "已开通"
                  : "未开通"
              }}</el-tag
            >
          </p>
          <p>
            上货折扣 :<span
              >{{ $store.state.userinfo.taocan.zhekou * 10 }}折</span
            >
            &nbsp;&nbsp;&nbsp;&nbsp; 
            剩余采集次数:<span
              >{{ $store.state.userinfo.usernum}}</span
            >
          
          </p>
        
        </div>
      </el-col>

      <el-col :span="24" v-if="$store.state.userinfo" style="margin-top:20px">
        <el-row :gutter="20">
          <el-col :span="$store.state.isMobile ? 12 : 6">
            <div
              @click="goto(20, 604800)"
              class="content"
              style="cursor: pointer;display:block"
            >
              <h6 style="color:#353535">待发货</h6>
              <p class="num">{{ numbers.daifahuo }}</p>
            </div>
          </el-col>
          <el-col :span="$store.state.isMobile ? 12 : 6">
            <div
              @click="goto(10, 604800)"
              class="content"
              style="cursor: pointer;display:block"
            >
              <h6>待支付</h6>
              <p class="num">{{ numbers.daizhifu }}</p>
            </div>
          </el-col>
          <el-col :span="$store.state.isMobile ? 12 : 6">
            <div class="content">
              <h6>产品数量</h6>
              <p class="num">{{ numbers.chanpinshu }}</p>
            </div>
          </el-col>
          <el-col :span="$store.state.isMobile ? 12 : 6">
            <div class="content">
              <h6>代发订单</h6>
              <p class="num">{{ numbers.daifashu }}</p>
            </div>
          </el-col>
        </el-row>
      </el-col>

      <el-col :span="24" v-if="$store.state.userinfo">
        <img :src="$store.state.userinfo.adimg" class="adimg" alt="" />
      </el-col>

      <div style="height:50px"></div>

      <div id="alert" v-show="buy || isMobile || bindweixin">
        <div class="contents" v-show="buy">
          <h6>微信扫码续费</h6>
          <div class="qrcode" ref="qrCodeUrl"></div>

          <div class="btn">
            <el-button @click="close" type="primary" class="btns"
              >返回</el-button
            >
          </div>
        </div>

        <div class="contents" v-show="isMobile">
          <h6 class="tit">修改个人信息</h6>
          <div class="bindmobile">
            <span class="text">姓名：</span>
            <el-input
              v-model="name"
              value="text"
              placeholder="请输入姓名"
              class="input"
            ></el-input>
          </div>
           <div class="bindmobile">
            <span class="text">手机号：</span>
            <el-input
              v-model="phone"
              value="number"
              placeholder="请输入手机号"
              class="input"
            >
            </el-input>
          </div>

          <div class="bindmobile">
            <span class="text">验证码：</span>
            <el-input
              v-model="codeNum"
              value="number"
              placeholder="请输入验证码"
              class="input"
            >
             <el-button slot="append" type="success" :disabled="mobileCode.status?true:false" @click="getCode" :class="mobileCode.status?'active':''">{{mobileCode.status?mobileCode.time:'发送验证码'}}</el-button>
            </el-input>
          </div>
           <div class="bindmobile">
            <span class="text">地址：</span>
            <el-input
              v-model="address"
              value="number"
              placeholder="请输入地址"
              class="input"
            ></el-input>
          </div>
          <div class="choosebtn">
            <el-button type="primary" @click="bindMobile">确认</el-button>
            <el-button @click="closeMobile">取消</el-button>
          </div>
        </div>

        <div class="contents" v-show="bindweixin">
          <h6 class="tit">绑定微信</h6>

          <div class="qrcode" ref="wx"></div>

          <div class="btn">
            <el-button @click="openBindWx" type="primary" class="btns"
              >返回</el-button
            >
          </div>
        </div>
      </div>
    </el-row>

    <el-drawer title="消费记录" :visible.sync="drawer" :direction="direction">
      <div class="list">
        <div class="items" v-for="(item, index) in paylist" :key="index">
          <div class="left">
            <p>{{ item.detail }}</p>
            <p>{{ item.addtime }}</p>
          </div>
          <div
            class="right"
            :style="{ color: item.fee > 0 ? '#e54a2d' : '#5ac37c' }"
          >
            {{ item.fee }}
          </div>
        </div>
        <el-button
          type="primary"
          round
          size="small"
          @click="more"
          style="margin:0 auto;display:block"
          >查看更多</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>
<style lang="scss">
.vip p {
  font-size: 14px;
  line-height: 25px;
  span {
    color: #f56c6c;
    font-weight: bold;
  }
}

#home .content .status {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.list {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px;
  overflow: scroll;

  .items {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 20px;

    .left {
      flex: 1;
      height: 100%;
      p:first-child {
        width: 100%;
        height: 16px;
        font-weight: bold;
      }
      p:last-child {
        font-size: 12px;
        line-height: 12px;
        margin-top: 8px;
      }
    }
  }
}

.money {
  margin-left: 10px;
  font-size: 16px;
}

@media screen and (max-width: 750px) {
  .money {
    margin: 20px 10px;
  }
  #home .content .user {
    height: auto;
  }
  #home .content .status .time {
    margin-left: 0;
  }
}
  .qrcode {
      margin: 0 auto;

    canvas,
    img {
      display: block;
      margin: 0 auto;
    }
}



</style>

<script>
import QRCode from "qrcodejs2";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      buy: false,
      code: false,
      wx: false,
      isMobile: false,
      phone: "",
      name:"",
      address:"",
      bindweixin: false,
      numbers: "",
      drawer: false,
      direction: "rtl",
      page: 1,
      paylist: [],
      codeNum:'',
      mobileCode:{
        time:60,
        status:false,
      },
      rand:''
    };
  },
  methods: {



    //获取验证码
    getCode(){
      let {time,status}=this.mobileCode;
       if(!status&&this.phone){
         this.axios.get(this.api.code,{
           params:{
             mobile:this.phone
           }
         })
         .then((res)=>{
           if(res.code==0){
             this.$message({
              message: '短信发送成功，注意查收',
              type: 'success',
              duration:1000,
            });
            this.rand=res.rand

             this.mobileCode.status=true;
              let timer=setInterval(() => {
                  if(time<=0){
                    clearTimeout(timer)
                    this.mobileCode={
                      time:60,
                      status:false
                    }
                  }else{
                    console.log(1)
                    this.mobileCode.time=time--;
                  }
              }, 1000);
           }else{
              this.$message({
              message:'发送失败，请重试',
              type: 'error',
              duration:1000,
            });
           }
           
         })
         
       }
    },

    


    goto(id, time) {
      this.$router.push({
        name: "Order",
        params: {
          id,
          time
        }
      });
    },
    // 打开消费记录盒子
    openDrawer() {
      if (!this.drawer) {
        this.paylist = [];
        this.page = 1;
        this.getPaylist();
      }
      this.drawer = !this.drawer;
    },
    //  消费记录
    getPaylist() {
      this.axios
        .get(this.api.paylist, {
          params: {
            page: this.page
          }
        })
        .then(res => {
          if (res.data != "") {
            this.paylist = [...this.paylist, ...res.data];
          } else {
            this.$message({
              message: "暂无更多",
              type: "warning"
            });
          }
        });
    },
    more() {
      this.page++;
      this.getPaylist();
    },

    //获取销售数据
    getNumbers() {
      this.axios.get(this.api.numbers).then(res => {
        this.numbers = res;
      });
    },
    closeMobile() {
      this.isMobile = !this.isMobile;
    },
    openBindWx() {
      if (!this.bindweixin && !this.wx) {
        this.creatBindwx();
      }
      this.bindweixin = !this.bindweixin;
    },
    bindMobile() {
      if (this.phone.length != 11) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning"
        });

        return false;
      }
      if(this.codeNum==this.rand){
         this.axios
        .get(this.api.bindMobile, {
          params: {
            mobile: this.phone,
            name:this.name,
            address:this.address
          }
        })
        .then(res => {
          if (res.code == 1) {
            this.$message({
              message: "绑定成功",
              type: "success"
            });
            
          } else {
            this.$message({
              message: "绑定失败",
              type: "error"
            });
          }
          this.getUserinfo()
          this.isMobile = false;
        });
      }else{
        this.$message({
          message: "验证码不正确",
          type: "warning"
        });
      }
     
    },
    close() {
      if (!this.buy && !this.code) {
        this.creatQrCode();
      }
      this.buy = !this.buy;
    },
    buyvip() {
      if (this.$store.state.isMobile) {
        window.location.href = this.api.buyvip + "&";
      } else {
        this.close();
      }
    },
    creatQrCode() {
      const that = this;

      if (!this.code) {
        that.$nextTick(function() {
          var qrcode = new QRCode(that.$refs.qrCodeUrl, {
            text: that.api.buyvip + "&shopid=" + that.$store.state.userinfo.id,
            width: 140,
            height: 140,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H
          });

          console.log(qrcode);
          that.code = true;
        });
      }
    },
    creatBindwx() {
      const that = this;

      if (!this.wx) {
        that.$nextTick(function() {
          var wxcode = new QRCode(that.$refs.wx, {
            text: that.api.bindwx + "&shopid=" + that.$store.state.userinfo.id,
            width: 140,
            height: 140,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H
          });
          console.log(wxcode);
          that.wx = true;
        });
      }
    },
    //编辑个人信息
    openSetting(){
        if(!this.isMobile){
           this.name=this.$store.state.userinfo.open_name;
          this.address=this.$store.state.userinfo.address;
          this.phone=this.$store.state.userinfo.mobile;
        }
        this.isMobile = true;
    },
    // 个人信息
      getUserinfo() {
      this.axios.get(this.api.userinfo).then(res => {
        if(res.code=="-100"){
          window.location.href=this.api.loginout
        }else{
          this.$store.dispatch("userinfo", res);
        }
        
      });
    },
  },
  created() {
    this.getNumbers();
  },
  mounted() {},

  watch: {
    "$store.state.userinfo": function(status) {
     
      if (!status.mobile) {
        this.isMobile = true;
      }
      this.getNumbers();
    }
  }
};
</script>
