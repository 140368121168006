<template>
    <div id='Detail'>
        <div class="content" v-loading="loading">
            <i class="iconfont close" @click="close">&#xe713;</i>
            <div class="scroll-box" v-if='detail'>
                <div class="scroll-con">
                     <div class="product">
                <div class="left">
                  
                    <el-carousel trigger="click" height="300px" indicator-position='none'>
                        <el-carousel-item v-for="item in detail.covers" :key="item">
                             <img v-lazy="item" alt="">
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right">
                    <h4>{{detail.title}}</h4>
                    <div class="price">
                        <span class="sm-title">
                           协议价：
                        </span>
                        <span class="money">
                            {{detail.sale_price/100}}元
                        </span>
                        <span class="sm-title">
                           指导价：
                        </span>
                        <span class="money">
                            {{detail.guide_price/100}}元
                        </span>
                    </div>
                    <div class="shopname">
                        店铺名：{{detail.shop_name}}
                    </div>
                    <div class="kd">
                        运费： <el-tag :type="detail.is_free_shipping==1?'danger':'warning'" size="mini">{{detail.is_free_shipping==1?'包邮':'不包邮'}}</el-tag>
                    </div>
                    <div class="kd">
                        库存：{{detail.stock}}
                    </div>
                    <div class="kd">
                        分类：{{detail.third_category_name}}
                    </div>
                    <div class="kd">
                        <!-- source -->
                         <el-button size="medium" v-if='detail.source==2' @click="open"  type="danger">查看原商品</el-button>
                    </div>
                </div>
            </div>

            <div class="productcontent">
                <div class="top">
                    产品详情
                </div>
                <div class="detailpic" v-html="detail.description"></div>
            </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Detail',
    data(){
        return{
            detail:'',
            loading:true,
        }
    },
    methods: {
        getDetail(){
            let params={};
            if(this.$store.state.detail==0){
                params.goodsid=this.$store.state.detail.id
            }else{
                  params.goodsid=this.$store.state.detail.id
            }
            // this.$store.state.detail.type==1?this.api.getdetail:this.api.detail
            this.axios.get(this.api.getdetail,{
                params,
            })
            .then((res)=>{
                this.loading=false;
                this.detail=res.data
            })
        },
        open(){
            window.open("https://item.jd.com/"+this.$store.state.detail.third_id+".html ","_blank"); 
        },
        close(){
            this.$store.commit("changeDetail")
        },
    },
    created() {
        this.getDetail()
    },
}
</script>