import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    kefu:false,//客服弹窗
    userinfo:null,//用户信息
    alert:{
      status:false,
      type:true,
    },//弹窗
    productId:null,//产品id
    isMobile:false,//是否为移动端
    menu:false,//菜单展开
    detail:{
      status:false,
      id:'',
      third_id:'',
      type:1,
    },//查看产品详情
    add:{
      status:false,
      id:'',
      type:0,
    }

  },
  mutations: {
    changekefu (state) {
      // 变更状态
      state.kefu=!state.kefu
    },
    userinfo(state,userinfo){
      state.userinfo=userinfo
    },
    alertChange(state,type){
      state.alert.status=!state.alert.status;
      console.log(type)
      state.alert.type=type;

    },
    addProductId(state,id){
      state.productId=id
    },
    mobile(state){
      state.isMobile=!state.isMobile
    },
    openMenu(state){
      state.menu=!state.menu
    },
    changeDetail(state,data){
      state.detail.status=!state.detail.status
      data.id?state.detail.id=data.id:state.detail.id=''
      data.third_id?state.detail.third_id=data.third_id:state.detail.third_id=''
      state.type=data.type
    },
    changeAdd(state,data){
      state.add.status=!state.add.status
      data.id?state.add.id=data.id:state.add.id='';
      data.type?state.add.type=data.type:state.add.type='';
    }
  },
  actions: {
    userinfo(context,userinfo){
      context.commit('userinfo',userinfo)
    },
    
  },
  modules: {

  }
})
