<template>
    <div id='user'>
        <div class="menu" v-if='$store.state.isMobile' @click="openMenu">
            <i class="el-icon-s-unfold" style="font-size:24px"></i>
        </div>
        <div class="service" @click="open">
            <i class="iconfont">&#xe701;</i>
            <span>在线客服</span>
        </div>
        <div class="usericons" v-if='userinfo!=null'>
            <span>{{userinfo.title}}</span>
            <div class="logo">
                <img :src="[userinfo.pic?userinfo.pic:require('./../assets/logo.png')]" alt="">
            </div>
            <div class="logout" @click="logout">
                    <p><i class="el-icon-switch-button"></i>退出登录</p>
            </div>        
        </div>
    </div>
</template>
<script>
export default {
    name:'user',
    data(){
        return{
           
        }
    },
    methods: {
        open(){
            this.$store.commit('changekefu');
        },
        

        openMenu(){
            
            this.$store.commit('openMenu');
        },
        logout(){
            window.location.href=this.api.loginout
        }
    },
    computed:{
        userinfo(){
            return this.$store.state.userinfo
        } 
    }
}
</script>
<style lang="scss">
   
</style>