let config;
 
// 定义同步获取json文件数据方法
let syncGetJsonData = function (url) {
  let xhr = new XMLHttpRequest()
  xhr.open('get', url, false)
  xhr.send()
  return JSON.parse(xhr.responseText)
}

function getQueryString(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
      return decodeURIComponent(r[2])
  }
  return null
}

let m=getQueryString('m');
let i=getQueryString("i");

let path;

if(process.env.NODE_ENV=='production'){
  if(m=='yumi_dianqunduo'){
    path='/addons/yumi_dianqunduo/template/mobile/config/config'+i+'.json?r='
    // path='/config/config'+i+'.json?r='
  }else{
    path='/addons/yumi_dianqun/template/mobile/config/config.json?r='
    // path='/config/config.json?r='
  }
 
}else{
    path='/config/config.json?r='
}
config = syncGetJsonData(path + Math.random()) // 随机参数用于防止取缓存
 
export default config
