import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Load from '../views/Load.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/load',
    name: 'Load',
    component: Load
  },
  {
    path: '/collection',
    name: 'Collection',
    component: () => import(/* webpackChunkName: "about" */ '../views/Collection.vue')
  },
  {
    path:'/productlist',
    name:'ProductList',
    component:()=>import(/* webpackChunkName:"ProductList"*/ '../views/ProductList.vue')
  },
  {
    path:'/logs',
    name:'Logs',
    component:()=>import(/* webpackChunkName:"Logs"*/ '../views/Logs.vue')
  },
  {
    path:'/help',
    name:'Help',
    component:()=>import(/* webpackChunkName:"Help"*/ '../views/Help.vue')
  },
  {
    path:'/selection',
    name:'Selection',
    component:()=>import(/* webpackChunkName:"Selection"*/ '../views/Selection.vue')
  },
  
  {
    path:'/myproduct',
    name:'MyProduct',
    component:()=>import(/* webpackChunkName:"MyProduct"*/ '../views/MyProduct.vue')
  },
  {
    path:'/order',
    name:'Order',
    component:()=>import(/* webpackChunkName:"MyProduct"*/ '../views/Order.vue'),
  },
  {
    path:'/copy',
    name:'Copy',
    component:()=>import(/* webpackChunkName:"Copy"*/ '../views/Copy.vue')
  },
  {
    path:'/shopping',
    name:'Shopping',
    component:()=>import(/* webpackChunkName:"Shopping"*/ '../views/Shopping.vue')
  },
  {
    path:'/parentshop',
    name:'Parentshop',
    component:()=>import(/* webpackChunkName:"Parentshop"*/ '../views/Parentshop.vue')
  },
  {
    path:'/orderdetail/:id',
    name:'OrderDetail',
    component:()=>import(/* webpackChunkName:"OrderDetail"*/ '../views/OrderDetail.vue')
  },
  {
    path:'/myorder',
    name:'MyOrder',
    component:()=>import(/* webpackChunkName:"MyOrder"*/ '../views/MyOrder.vue')
  },
  {
    path:'/supplier',
    name:'Supplier',
    component:()=>import(/* webpackChunkName:"Supplier"*/ '../views/Supplier.vue')
  },
  {
    path:'/recording',
    name:'recording',
    component:()=>import(/* webpackChunkName:"Recording"*/ '../views/Recording.vue')
    
  },
  {
    path:'/invite',
    name:'invite',
    component:()=>import(/* webpackChunkName:"Recording"*/ '../views/Invite.vue')
  },
  {
    path:'/marketing',
    name:'marketing',
    component:()=>import(/* webpackChunkName:"Marketing"*/ '../views/Marketing.vue')
  },
  {
    path:"/userinvite",
    name:"userinvite",
    component:()=>import(/* webpackChunkName:"UserInvite"*/'../views/UserInvite.vue')
  }
  
]





const router = new VueRouter({
  routes
})

// router.beforeEach((to, from,next)=>{
 
//         // 默认只有二级
//         console.log(this.$refs.kzMenu)
//         let paths = to.path.split('/')
//         const path = paths && paths.length ? paths.slice(0, 3).join('/') : to.path
//         // element nav的数据是缓存的
//         // 重新传入list并不会更新路由地址作为的key
//         this.$refs.kzMenu.activedIndex = path
//   next()
// })


export default router
