import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import api from './api/api'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLazyload from 'vue-lazyload'
import VueClipboard from 'vue-clipboard2'
 
import {Switch,Timeline,TimelineItem,Drawer,Breadcrumb,BreadcrumbItem,Carousel,CarouselItem,Autocomplete,Badge,Image,Notification,InfiniteScroll,Popover,Button,Select,Row,Col,Menu,Submenu,MenuItem,MenuItemGroup,Tag, Form,FormItem,Input, Option,OptionGroup,Table,TableColumn,Pagination,Loading,Message,MessageBox,Cascader,} from 'element-ui';

import config from './utils/sync-config'



Vue.use(VueLazyload)

axios.defaults.timeout=8000;
axios.defaults.withCredentials=true;

let nodeEnv = process.env.NODE_ENV;



axios.interceptors.request.use(config => {
  
  if(nodeEnv=='development'){
    if(config.method=='post'){
      config.data.shopid='186';
    }else{
      
      config.url=config.url+'&shopid=186'
    }
  }
 

  return config
}, error => {
  return Promise.reject(error)
})


axios.interceptors.response.use(function(response){
  let res=response.data;
  return Promise.resolve(res);
  
}, function (error) {
   console.log(error)
  // 对响应错误做点什么
  if (error.response.status === 401) {
    window.location.href='/'
  }
  return Promise.reject(error);
})

Vue.use(VueAxios,axios)


Vue.use(VueClipboard)

Vue.use(Button)
Vue.use(Select)
Vue.use(Row)
Vue.use(Col)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Switch);
Vue.use(Tag)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input);
Vue.use(Option)
Vue.use(OptionGroup);
Vue.use(TableColumn);
Vue.use(Table);
Vue.use(Pagination);
Vue.use(Cascader);
Vue.use(Loading);
Vue.use(Popover);
Vue.use(Image);
Vue.use(Badge);
Vue.use(Autocomplete);
Vue.use(CarouselItem);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Carousel);
Vue.use(Drawer);
Vue.use(Timeline);
Vue.use(TimelineItem);

Vue.use(InfiniteScroll);
Vue.prototype.$notify = Notification;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$alert = MessageBox.alert;
Vue.config.productionTip = false;
Vue.prototype.api=config;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
