<template>
  <div id="app">
    <user />

    <alert v-if="$store.state.alert.status" />

    <detail v-if="$store.state.detail.status" />

    <add v-if="$store.state.add.status" />

    <div
      class="menushow"
      :class="$store.state.menu ? 'active' : ''"
      @click="openMenu"
    ></div>

    <div class="menubox" :class="$store.state.menu ? 'active' : ''">
      <div v-if="$store.state.userinfo" class="logos">
        <img
          style="width:30px;height:30px;"
          :src="$store.state.userinfo.logo"
        />
        <span>{{ $store.state.userinfo.daili_title }}</span>
      </div>
      <!-- 左侧菜单 unique-opened -->
      <div id='menu'>
              <el-menu
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        @select="goto"
        :default-openeds="openeds"
        v-if="$store.state.userinfo"
        
        router
        ref="kzMenu"
        :default-active="$route.path"
      >
        <template v-for="(item, index) in menu">
          <template v-if="item.path">
            <el-menu-item :index="item.path" :key="index">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </template>
          <template v-else>
            <el-submenu :index="index + ''" :key="index">
              <template slot="title">
                <i :class="item.icon"></i>
                <span>{{ item.name }}</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  v-for="(items, i) in item.children"
                  :key="i"
                  :index="items.path"
                >
                  <el-badge
                    v-if="items.new"
                    value="new"
                    class="item"
                    style="line-height: 30px;"
                  >
                    {{ items.name }}
                  </el-badge>
                  <span v-else> {{ items.name }}</span>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </template>
        </template>
      </el-menu>
      </div>


    </div>

    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
// import Header from './../components/Header';
import User from "./components/User";
import Alert from "./components/Alert";
import Detail from "./components/Detail";
import Add from "./components/Add";
export default {
  data() {
    return {
      src: "./../assets/logo.png",
      userinfo: null,
      openeds: ["4"],
      activeIndex: "1",
      menu: [
        {
          name: "首页",
          path: "/",
          icon: "el-icon-s-home"
        },
        {
          name: "商品搬家",
          icon: "el-icon-s-shop",
          children: [
            //  {
            //   name: "店铺同步",
            //   path: "/copy",
            //   new:false,
            // },

            {
              name: "采集列表",
              path: "/productlist"
            },
            {
              name: "采集记录",
              path: "/logs"
            }
          ]
        },
        {
          name: "商品管理",
          icon: "el-icon-s-tools",
          children: [
            {
              name: "商品列表",
              path: "/myproduct"
            }
          ]
        },
        {
          name: "订单管理",
          icon: "el-icon-s-order",
          children: [
            {
              name: "订单列表",
              path: "/order"
            },
            {
              name: "代发列表",
              path: "/myorder"
            }
          ]
        },

        {
          name: "货源市场",
          icon: "el-icon-s-goods",
          children: []
        },
        {
          name: "营销中心",
          icon: "el-icon-share",
          path: "/marketing"
        },
        {
          name: "帮助中心",
          icon: "el-icon-warning",
          children: [
            {
              name: "操作手册",
              path: "/help"
            }
          ]
        },
      
      ]
    };
  },
  components: {
    User,
    Alert,
    Detail,
    Add
  },

  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      
      console.log(key, keyPath);
    },
    goto() {
      if (this.$store.state.isMobile) {
       
        this.$store.commit("openMenu");
      }
    },
    looseBody () {
        let body = document.body
        body.style.position = ''
        let top = body.style.top
        document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top)
        body.style.top = ''
      },
       fixedBody () {
            let scrollTop = document.body.scrollTop || document.documentElement.scrollTop
            document.body.style.cssText += 'position:fixed;width:100%;top:-' + scrollTop + 'px;'
        },

    //获取用户信息
    getUserinfo() {
      this.axios.get(this.api.userinfo).then(res => {
        if (res.code == "-100") {
          window.location.href = this.api.loginout;
        } else {
          this.$message({
            message: "登录成功",
            duration: 500,
            type: "success"
          });
          if (res.type == "供应商") {
            this.menu.push({
              name: "供应商入口",
              icon: "el-icon-s-custom",
              children: [
                {
                  name: "订单管理",
                  path: "/supplier",
                  new: false
                },
                {
                  name: "财务管理",
                  path: "/recording"
                },
                {
                  name: "门店拓客",
                  path: "/invite",
                  new: false
                }
              ]
            });
          } else {
             this.menu.push({
              name: "我要推广",
              icon: "el-icon-s-custom",
              children: [
               
                {
                  name: "我的收入",
                  path: "/recording"
                },
                {
                  name: "邀请好友",
                  path: "/userinvite",
                  new: false
                }
              ]
            });
          }
          if (res.fromshopname && res.gzhucid) {
            this.menu[4].children.unshift({
              name: res.fromshopname,
              path: "/parentshop",
              new: true
            });
          }
          if (res.caiji != 1) {
            this.menu[1].children.unshift({
              name: "一键采集",
              path: "/collection"
            });
          }
          if (res.bendicang == 0) {
            this.menu[4].children.push({
              name: res.bendi_name,
              path: "/Selection"
            });
          }
          if (res.yuncang != 1) {
            this.menu[4].children.push({
              name: res.yun_name,
              path: "/shopping",
              new: false
            });
          }
          this.$store.dispatch("userinfo", res);
        }
      });
    },
    openMenu() {
      this.$store.commit("openMenu");
    },
    open() {
      const _this = this;
      this.$alert(
        `<img style='width:200px;height:200px;display:block;margin:0 auto' src='${this.$store.state.userinfo.kfimg}' >`,
        "客服微信",
        {
          dangerouslyUseHTMLString: true,
          showClose: false,
          beforeClose(action, instance, done) {
            console.log(action);
            _this.$store.commit("changekefu");
            done();
          }
        }
      );
    }
  },
  created() {
    this.getUserinfo();

    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      this.$store.commit("mobile");
    } else if (/(Android)/i.test(navigator.userAgent)) {
      this.$store.commit("mobile");
    }
  },
  mounted() {},
  computed: {},
  watch: {
    "$store.state.kefu": function(status) {
      if (status) {
        this.open();
      }
    },
    "$store.state.menu": function(status) {
      if (!status) {
        this.looseBody()
      }else{
        
        this.fixedBody()
      }
    }
  }
};
</script>
<style lang='scss'>
@import "./assets/scss/reset.scss";
@import "./assets/scss/app.scss";
</style>
